<template>
  <div class="col-lg-12">
    <hot-offer-icon class="hot-offer-icon" :color="item.badgeColor" :text="item.badgeText" v-if="isBadge"/>

    <div class="lesiurpackages_hotelsbox domestic_hotelsbox" :class="isBig && 'big'">
      <b-img-lazy :src="imageUrl"
        width="100"
        height="100"
        alt="lesiurpackages_hotelsimg5"
        v-bind="mainProps"
        :onerror="`this.src='${imageDomain}/assets/img/has_no_picture.jpg'`"
      />
      <div class="disc" v-if="discountPercent > 0 && !isBadge">
        <strong>{{discountPercent}}%</strong><br /> {{ $t("home.discount") }}
      </div>
      <div class="lesiurpackages_hotelstextbox">

        <h3> {{ item.hotelName }}, {{ item.extendedProperties.destination.name}}</h3>
        <p class="item-sub-title">
          <i class="fa fa-calendar-alt"></i> <span dir="ltr">{{ changeDateFormat(item.checkinDate) }} - {{ changeDateFormat(item.checkoutDate) }}</span>
        </p>

        <a :href="`${domesticDomain}${item.productPageUrl}`"><div class="place btn-primary">{{ $t("home.domestic-order-button") }}</div></a>

        <div class="d-flex w-100 d-inline-block">
          <div class="clock">
            <p><i class="fa fa-question-circle"></i> {{$t("home.what-including-in-price")}}</p>
            <p>
              <i class="fa fa-check"></i>
              {{$t("home.total-night")}}: {{ calcNight(item.checkoutDate, item.checkinDate) }}
              {{ (calcNight(item.checkoutDate, item.checkinDate) > 1) ? $t("home.nights") : $t("home.night") }}
           </p>
            <p><i class="fa fa-check"></i>{{ item.priceRemark }}</p>

          </div>
          <div class="align-items-center" :class="(device==='desktop') && isBig ? 'd-inline-flex': 'd-inline-block'" style="width:30%;">
            <p class="price">
              <span class="currency-symbol">₪</span><strong>{{Math.round(item.totalAfterDiscount)}}</strong>
            </p>
            <p style="font-size:0.8rem;"> {{$t("home.total-price-for-the-package")}} </p>
          </div>
        </div>
      </div>
    </div>

    <countdown class="collapse-button"
      :end-time="item.specialDiscount.endDate" @finish="finishCountdown" v-if="isTimer">
      <template v-slot:process="time">
        <h5>
          <b-badge variant="success" v-if="time.timeObj.d !== '0'">
            {{ $t("home.count-down-day", { day: time.timeObj.d, hour: time.timeObj.h, minute: time.timeObj.m, second: time.timeObj.s}) }}
          </b-badge>
          <b-badge variant="success" v-else-if="time.timeObj.d === '0' && time.timeObj.h !== '00'">
            {{ $t("home.count-down-hour", { hour: time.timeObj.h, minute: time.timeObj.m, second: time.timeObj.s}) }}
          </b-badge>
          <b-badge variant="success" v-else-if="time.timeObj.d === '0' && time.timeObj.h === '00' && time.timeObj.m !== '00'">
            {{ $t("home.count-down-minute", { minute: time.timeObj.m, second: time.timeObj.s}) }}
          </b-badge>
          <b-badge variant="success" v-else>
            {{ $t("home.count-down-second", { second: time.timeObj.s}) }}
          </b-badge>
        </h5>
      </template>
    </countdown>
    <MoreDealCollapse :index="item.id" :moreItems="moreItems" class="collapse-button" v-if="moreItems && moreItems.length > 0"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BImgLazy, BBadge } from 'bootstrap-vue';
import dayjs from 'dayjs';
import imageUrlMixin from '@/utils/imageUrlMixin';

const customParseFormat = require('dayjs/plugin/customParseFormat');

const { VUE_APP_DOMESTIC_DOMAIN } = process.env;

dayjs.extend(customParseFormat);

export default {
  mixins: [imageUrlMixin],
  components: {
    BImgLazy,
    BBadge,
    HotOfferIcon: () => import('@/components/atoms/HotOfferIcon'),
    countdown: () => import('@/components/atoms/CountDown'),
    MoreDealCollapse: () => import('@/components/atoms/MoreDealCollapseForDomestic'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
    }),
    item() {
      const valueArray = this.items[1];
      // if (valueArray && valueArray[0].categoryId === 'Organize_tour_packages') console.log(valueArray, 111);
      return (valueArray) ? valueArray[0] : null;
    },
    moreItems() {
      const valueArray = this.items[1];
      return (valueArray && valueArray.length > 1) ? valueArray.slice(1) : [];
    },
    isBadge() {
      return this.item?.badgeColor && this.item?.badgeText;
    },
    isTimer() {
      return this.item?.specialDiscount && this.item?.specialDiscount.endDate && new Date(this.item?.specialDiscount.endDate) > new Date();
    },
    discountPercent() {
      return (this.item?.specialDiscount?.discountPercent && new Date(this.item?.specialDiscount?.endDate) > new Date()) ? this.item?.specialDiscount?.discountPercent : this.item?.discountPercent;
    },
    imageUrl() {
      const { item } = this;
      let url = '';

      if (!item) return '';
      url = (item.imageUrl !== 'null') ? url = item.imageUrl : null;
      url = url || item.extendedProperties.hotelInfo.mainImageUrls[0] || item.extendedProperties.hotelConversion.imageUrl || `${this.imageDomain}/assets/img/lesiurpackages_hotelsimg5.jpg`;
      return url;
    },
    domesticDomain() {
      return VUE_APP_DOMESTIC_DOMAIN;
    },
  },
  watch: {
    lang() {
      // console.log(this.lang);
    },
  },
  data() {
    return {
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
      isSalesDealExpired: false,
    };
  },
  methods: {
    changeDateFormat(str) {
      return str.split('T')[0].split('-').reverse().join('/');
    },
    calcNight(day1, day2) {
      const a = dayjs(this.changeDateFormat(day1), 'DD/MM/YY');
      const b = dayjs(this.changeDateFormat(day2), 'DD/MM/YY');
      return a.diff(b, 'day');
    },
    finishCountdown() {
      this.isSalesDealExpired = true;
    },
  },
};
</script>

<style scoped>
.collapse-button {
  position: absolute;
  z-index: 100;
  left: 30px;
  top: 10px;
}
.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p:first-child{
  margin-top: revert;
}
.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
  width:80%;
}
.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .item-sub-title{
  text-align:center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
}
.price{
  padding-top: 20px;
}
.place {
  padding: 8px 10px;
  content: "";
  background: #005ca3;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  border-radius: 10px;
  z-index: 91;
  width: 120px;
  margin: 10px auto;
  border: 1px solid rgb(255 255 255 / 0.5);
  font-weight: bold;
}
.lesiurpackages_hotelstextbox a:hover {
  text-decoration: none;
}
.lesiurpackages_box.Oregenizedbox .lesiurpackages_hotelstextbox p {
  margin-bottom: 20px;
}
.domestic-hotel-name{
  font-weight: bold;
  font-size: 1.3em;
}
.domestic_hotelstextbox{
  background: white;
  padding-bottom: 20px;
}
.last-paragraph{
  border-bottom: 1px solid;
}
.what-description{
  font-weight: bold;
}
.text-color-blue{
  color:#1425f5;
}
.lesiurpackages_hotelsbox.domestic_hotelsbox .place {
  width: fit-content;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
  width: auto;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
  white-space: break-spaces;
  display: flex;
  margin-bottom: 3px;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox img {
  filter: brightness(0.8);
}
.domestic_hotelsbox.lesiurpackages_hotelsbox:hover img {
  filter: brightness(1);
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .d-inline-block p {
  font-size: 0.9rem;
}
.domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock i {
  margin-left: 5px;
}
.img-content{
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox {
    text-align: center;
  }
}
@media (min-width: 480px) {
  .lesiurpackages_hotelsbox.big .lesiurpackages_hotelstextbox .item-sub-title{
    margin-bottom: 130px;
  }
  .lesiurpackages_hotelsbox.big h3{
    font-size: 3em;
  }
  .lesiurpackages_hotelsbox.big .lesiurpackages_hotelstextbox .item-sub-title{
    font-size: 2em;
  }
  .lesiurpackages_hotelsbox.big .place{
    padding: 10px 10px;
    font-size: 1.2rem;
  }
  /* .lesiurpackages_hotelsbox:not(.big) .price, .domestic_hotelsbox .price{
    text-align: center;
  } */
  .lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox .d-inline-block p{
    line-height: 1em;
  }
}

  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
    white-space: nowrap;
  }
@media (max-width: 480px) {
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox .d-inline-block p {
    font-size: 0.9em;
  }
  .lesiurpackages_hotelsbox {
    height: 350px;
  }
  /* .domestic_hotelsbox .domestic_hotelstextbox h4{
    font-size: 1.3rem;
  } */
  .lesiurpackages_hotelsbox.big .currency-symbol{
    font-size: initial;
  }
  .lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox{
    padding: 4px
  }
}
.hot-offer-icon {
  width: 100px;
  height: 100px;
  top: -6px;
  right: 9px;
  position: absolute;
  /* transform: rotate(90deg); */
  z-index: 10;
}
</style>

<style>
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .domestic_hotelsbox.lesiurpackages_hotelsbox:not(.big) .lesiurpackages_hotelstextbox div.clock{
    width: 80% !important;
  }
  .domestic_hotelsbox.lesiurpackages_hotelsbox .lesiurpackages_hotelstextbox div.clock p{
    white-space: normal !important;
  }
  .lesiurpackages_hotelsbox.domestic_hotelsbox .place {
    width: 80% !important;
  }
}
</style>
